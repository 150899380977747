/*
 * @Author: your name
 * @Date: 2022-03-22 16:02:45
 * @LastEditTime: 2022-04-26 13:17:31
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \ananyuweiback\src\api\user.js
 */
import { request, requestFd } from '../core/request'

// 获取登录验证码
export function _getLoginSmsPin (params) {
  console.log('获取登录验证码')
  return request({
    url: '/rbac/api/v1/user/webGetLoginSmsPin',
    method: 'get',
    params: params,
    showLoading: 'true',
    tokenStatus: false // 是否加token
  })
}
// webGetRegisterSmsPin getRegisterSmsPin  webGetLoginSmsPin getLoginSmsPin
// 获取注册验证码
export function _getRegisterSmsPin (params) {
  console.log('获取注册验证码')
  return request({
    url: '/rbac/api/v1/user/webGetRegisterSmsPin',
    method: 'get',
    params: params,
    showLoading: 'true',
    tokenStatus: false // 是否加token
  })
}

// 注册
export function _register (params) {
  console.log('注册请求')
  return request({
    url: '/rbac/api/v1/user/register',
    method: 'post',
    data: params,
    showLoading: 'true',
    tokenStatus: false // 是否加token
  })
}


// 登录请求
export function _logins (params) {
  console.log('登录请求')
  return request({
    url: '/rbac/api/v1/user/login',
    method: 'post',
    data: params,
    showLoading: 'true',
    tokenStatus: false // 是否加token
  })
}


// 所有的按钮的code
export function _rbacPermission (params) {
  console.log('登录请求')
  return request({
    url: '/rbac/api/v1/user/rbacPermission',
    method: 'post',
    data: params,
    showLoading: 'true',
    tokenStatus: true // 是否加token
  })
}


// 我的页面根据token获取个人信息
export function _getMine (params) {
  console.log('登录请求')
  return request({
    url: '/rbac/api/v1/pri/organ/getInfoByToken',
    method: 'get',
    params: params,
    showLoading: 'true',
    tokenStatus: false // 是否加token
  })
}